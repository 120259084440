import { Avatar, AvatarInitial } from "@doar/components";
import { useEffect, useState } from "react";
import { authenticationService } from "src/components/settings/data";
import { Login } from "src/graphql/generated/owner-schema";
import Alerts from "./alerts";
import LoggedinUser from "./loggedin-user";
import { StyledUser, StyledAvatarWrap } from "./style";

const AsideUser = () => {

    const [currentUser, setCurrentUser] = useState<Login>();


    useEffect(() => {
        authenticationService.currentUser.subscribe(currentUserCallback => {
            const user = currentUserCallback();
            setCurrentUser(user)
        });
    }, [])

    return (
        <StyledUser className="aside-user">
            <StyledAvatarWrap>
                <Avatar size="md">
                    <AvatarInitial>
                        {(currentUser?.firstName??'Anonymous')?.substring(0, 1)}
                        {(currentUser?.lastName??'Anonymous')?.substring(0, 1)}
                    </AvatarInitial>
                </Avatar>
                <Alerts />
            </StyledAvatarWrap>
            <LoggedinUser currentUser={currentUser} />
        </StyledUser>
    );
};

export default AsideUser;
