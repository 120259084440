/* eslint-disable @typescript-eslint/no-unsafe-return */
import styled, {device, themeGet, tinycolor, css} from "@doar/shared/styled";
import {Col, NavLink} from "@doar/components";
import browserImage from "@doar/shared/images/landing/browser1.png";
import phoneImage from "@doar/shared/images/landing/phone.png";

interface IProps {
    $minimize: boolean;
    $maximize: boolean;
    $mdMinimize: boolean;
}

interface IVisibleProps {
    $show: boolean;
}
interface IAsideProps extends IProps, IVisibleProps {}

export const StyledHeader = styled.div`
  background-color: #fff;
  height: 55px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${themeGet("colors.border")};
  //padding-left: 65px;
  ${device.large} {
    height: 60px;
    padding: 0 25px;
  }

  .nav {
    &-link {
      &:not(:first-of-type) {
        margin-left: 10px;
      }

      line-height: 1;
      padding: 0;
      color: ${(props) =>
              tinycolor(props.theme.colors.text2 as string)
                      .setAlpha(0.75)
                      .toString()};

      svg {
        width: 18px;
        height: 18px;

        ${device.large} {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  ${(props) =>
          props.theme.name !== "dark" &&
          css`
            background-color: #fff;
          `}
  ${(props) =>
          props.theme.name === "dark" &&
          css`
            background-color: ${themeGet("colors.gray900")};
          `}
`;

export const StyledCol = styled(({zIndex, ...rest}) => <Col {...rest}/>)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  //align-items: center;
  z-index: 1;

  &.left {
    height: 100%;
    .app-call-to-action {
      padding: 50px 50px 0 50px;
    }

    .reviewers {
      .avatar {
        margin: -7px;
        border: 2px solid white;
        box-shadow: 0 0 1px 1px #00000024;
      }
    }
  }

  &.background {
    height: 100%;
    position: absolute;
    right: 0;
    z-index: 0;

    .browser-image {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url(${browserImage});
      background-repeat: no-repeat;
      background-size: 1000px;
      background-position-x: calc(100vw - 400px);
      background-position-y: calc((100vh / 2) - 416px);
      filter: blur(8px);

      ${device.small} {
        background-position-x: calc(100vw - 480px);
        background-position-y: calc((100vh / 2) - 416px);
      }

      ${device.medium} {
        filter: blur(0px);
      }

      ${device.large} {
        background-position-x: calc(100vw - 600px);
        background-position-y: calc((100vh / 2) - 416px);
      }

      ${device.xlarge} {
        background-position-x: calc(100vw - 700px);
        background-position-y: calc((100vh / 2) - 416px);
      }

      ${device.xxlarge} {
        background-position-x: calc(100vw - 800px);
        background-position-y: calc((100vh / 2) - 416px);
      }

      ${device["3xlarge"]} {
        background-position-x: calc(100vw - 900px);
        background-position-y: calc((100vh / 2) - 416px);
      }

      ${device["4xlarge"]} {
        background-position-x: calc(100vw - 1000px);
        background-position-y: calc((100vh / 2) - 416px);
      }
    }

    .phone-image {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-image: url(${phoneImage});
      background-repeat: no-repeat;
      background-size: 800px;
      background-position-x: calc(100vw - 500px);
      background-position-y: calc((100vh / 2) - 230px);
      filter: blur(8px);
      
      ${device.small} {
        background-position-x: calc(100vw - 600px);
        background-position-y: calc((100vh / 2) - 230px);
      }
      
      ${device.medium} {
        filter: blur(0px);
      }
    }
  }

  .app-description {
    h1{
      //color: ${themeGet("colors.white")};
      text-shadow: 0 0 9px #00000061;
      font-size: 30px;
      ${device.medium} {
        color: ${themeGet("colors.text2")};
        text-shadow: none;
        font-size: 50px;
        padding: 0 25px;
      }
    }
    
  }
  .mdRowReversed{
    flex-direction: column-reverse;
    ${device.medium}{
      flex-direction: row;
    }
  }
  
`;
export const StyledNavLink = styled(({ ...rest}) => <NavLink {...rest}/>)`
  display: none;
  ${device.small} {
    display: unset;
  }
`;

export const StyledMenuBtn = styled.button`
    padding: 0;
    background-color: transparent;
    border: none;
    line-height: 0.5;
    svg {
        color: ${themeGet("colors.text3")};
        margin-top: -3px;
    }
    &:hover,
    &:focus {
        svg {
            color: ${themeGet("colors.text2")};
        }
    }
    &.minimize-btn {
      display: block;
        ${device.small} {
          display: none;
        }
    }
    &.display-btn {
      display: none;
        ${device.small} {
          display: block;
        }
        svg {
            &:last-of-type {
                margin-top: 0;
                display: none;
            }
        }
    }
    ${({ theme }) =>
    theme.name === "dark" &&
    css`
            &:hover {
                color: ${themeGet("colors.white")};
            }
        `}
`;


const minimizeCSS = css`
    width: auto;
    & + .content {
        margin-left: 60px;
    }
    .aside-body-inner {
        //padding: 20px;
    }
    .aside-user {
        margin-bottom: 5px;
        .avatar {
            width: 26px;
            height: 26px;
            flex-shrink: 0;
            margin-left: -4px;
            &-initial {
                font-size: 10px;
            }
        }
        .aside-alerts {
            opacity: 0;
            visibility: hidden;
            position: fixed;
        }
    }
    .aside-loggedin-user {
        &-data {
            display: none;
        }
        &-nav {
            display: block;
            .nav {
                width: 100%;
                flex-wrap: nowrap;
            }
            svg {
                margin-right: 0;
            }
            span {
                position: fixed;
                opacity: 0;
                visibility: hidden;
                margin-left: 15px;
            }
        }
    }
    .aside-navbar {
        width: 100%;
        flex-wrap: nowrap;
        &-label {
            display: none;
        }
        &-link {
            svg {
                margin-right: 0;
            }
            span {
                position: fixed;
                opacity: 0;
                visibility: hidden;
                margin-left: 15px;
            }
            &:before {
                right: auto;
                left: -20px;
            }
            &:after {
                display: none;
            }
        }
        .submenu {
            position: relative;
            & > li {
                &:first-of-type {
                    margin-top: 10px;
                }
            }
            &:before {
                content: "";
                display: block;
                border-top: 1px solid ${themeGet("colors.border")};
                margin: 8px 0;
            }
        }
    }
`;

const maximizeCSS = css`
    .scrollbar {
        width: 240px;
    }
    .aside {
        &-body {
            width: 100%;
            display: block;
            &-inner {
                //padding: 20px;
            }
        }
        &-user {
            .aside-alerts {
                opacity: 1;
                visibility: visible;
                position: relative;
            }
        }
        &-loggedin-user {
            &-nav {
                span {
                    position: relative;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        &-navbar {
            &-link {
                span {
                    position: relative;
                    opacity: 1;
                    visibility: visible;
                }
                &:after {
                    display: block;
                }
            }
        }
    }
`;

export const StyledLandingMenu = styled.aside<IAsideProps>`
    width: 240px;
    height: 100%;
    transform: translateX(-100%);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    .scrollbar {
        .ps__thumb-x {
            display: none;
        }
    }
    ${({ $show }) =>
    $show &&
    css`
            transform: translateX(0);
        `}

    ${({ $minimize }) =>
    $minimize &&
    css`
            ${minimizeCSS}
        `}

    ${({ $mdMinimize, $show }) =>
    $mdMinimize &&
    !$show &&
    css`
            ${device.lgToXl} {
                ${minimizeCSS}
            }
        `}

    ${({ $mdMinimize, $show, $maximize }) =>
    $mdMinimize &&
    !$show &&
    !$maximize &&
    css`
            ${device.lgToXl} {
                .mega-submenu {
                    display: none;
                }
            }
        `}

    ${({ $maximize }) =>
    $maximize &&
    css`
            ${maximizeCSS}
        `}

    ${({ $minimize, $maximize }) =>
    $minimize &&
    !$maximize &&
    css`
            .mega-submenu {
                display: none;
            }
        `}

    ${device.large} {
        transform: translateX(0);
    }
`;

