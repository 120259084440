import {SpaceProps} from "@doar/shared/styled";
import cn from "clsx";
import {StyledContent} from "./style";

interface IProps extends SpaceProps {
    children: React.ReactNode;
    className?: string;
    style?: any;
    fullHeight?: boolean;
    align?: "top" | "center" | "bottom";
    aside?: "minimize" | "hidden";
    noMenu?: boolean
}

const Content = ({
                     children,
                     className,
                     style,
                     fullHeight,
                     align,
                     aside,
                     noMenu,
                     ...restProps
                 }: IProps) => {
    return (
        <StyledContent
            $fullHeight={fullHeight}
            $align={align}
            $aside={aside}
            $noMenu={noMenu}
            className={cn(className, "content")}
            style={style}
            {...restProps}
        >
            {children}
        </StyledContent>
    );
};

export default Content;
