import cn from "clsx";
import {ISubmenu} from "@doar/shared/types";
import {StyledSubmenu, StyledSubNavItem, StyledSubNavlink} from "./style";
import {Dispatch, SetStateAction, useState} from "react";

interface IProps {
    menu: ISubmenu[];
    className?: string;
    setMenuUpdated: Dispatch<SetStateAction<boolean>>
}

const Submenu = ({menu, className, setMenuUpdated}: IProps) => {


    return (
        <StyledSubmenu className={className}>
            {menu.map(({id, label, url, Icon}) => (
                <StyledSubNavItem key={id} className={label.toLowerCase().replaceAll(" ", "-")}>
                    <StyledSubNavlink
                        path={url}
                        onClick={() => setMenuUpdated((menuUpdated:boolean)=>!menuUpdated)}
                        className={cn(
                            location.pathname === url ? "active" : "",
                            "aside-navbar-link"
                        )}
                    >
                        {Icon && <Icon/>}
                        <span>{label}</span>
                    </StyledSubNavlink>
                </StyledSubNavItem>
            ))}
        </StyledSubmenu>
    );
};

export default Submenu;
