import {
    MessageSquare,
    Users,
    PieChart,
    Package,
    DollarSign,
    BarChart,

    Home, Tool, Phone, TrendingUp,
} from "react-feather";
import {Role} from "@doar/main/src/graphql/generated/owner-schema";

const asideMenus = [
    {
        id: 1,
        label: "Dashboard",
        url: "/dashboard",
        Icon: PieChart,
        roles:[Role.Owner, Role.CoOwner, Role.RealOwner, Role.Agent],
        submenu: [
            {
                id: 11,
                label: "Dashboard",
                url: "/dashboard",
                Icon: PieChart,
                roles:[Role.Owner, Role.CoOwner, Role.RealOwner, Role.Agent],
                description:"View your summaries"
            },
            {
                id: 12,
                label: "Property Insights",
                url: "/insights",
                Icon: TrendingUp,
                roles:[Role.Owner, Role.CoOwner, Role.RealOwner],
                description:"View your summaries"
            },
        ],
    },
    {
        id: 2,
        label: "Management",
        url: "/management",
        Icon: PieChart,
        roles:[Role.Owner, Role.CoOwner, Role.RealOwner, Role.Agent],
        submenu: [
            {
                id: 21,
                label: "Houses & Apartments",
                url: "/properties",
                Icon: Home,
                roles:[Role.Owner, Role.CoOwner, Role.RealOwner, Role.Agent],
                description:"View and manage all your apartments, houses lands & plots for rent/sale"
            },
            {
                id: 22,
                label: "Tenants",
                url: "/tenants",
                Icon: Users,
                roles:[Role.Owner, Role.CoOwner, Role.RealOwner],
                description:"View and manage all your tenants"
            },
            {
                id: 23,
                label: "Maintenance",
                url: "/maintenance",
                Icon: Tool,
                roles:[Role.Owner, Role.CoOwner, Role.RealOwner],
                description:"View and manage all mantenance requests to your house"
            },
            {
                id: 23,
                label: "Accounting",
                url: "/accounting",
                Icon: DollarSign,
                roles:[Role.Owner, Role.CoOwner, Role.RealOwner],
                description:"View and manage your properties accounting & finances"
            },
            {
                id: 23,
                label: "Reports",
                url: "/reports",
                Icon: BarChart,
                roles:[Role.Owner, Role.CoOwner, Role.RealOwner],
                description:"View and manage all reports from your properties"
            },
        ],
    },
    {
        id: 3,
        label: "Communication",
        url: "/communication",
        Icon: Package,
        roles:[Role.Owner, Role.CoOwner, Role.RealOwner, Role.Agent],
        submenu: [
            {
                id: 31,
                label: "Messages",
                url: "/messages",
                Icon: MessageSquare,
                roles:[Role.Owner, Role.CoOwner, Role.RealOwner, Role.Agent],
                description:"View and reply to all messages sent to your properties"
            },
            {
                id: 32,
                label: "Contacts",
                url: "/contacts",
                Icon: Phone,
                roles:[Role.Owner, Role.CoOwner, Role.RealOwner, Role.Agent],
                description:"View & manage all your added contacts"
            }
        ],
    },
    {
        id: 3,
        label: "Administration",
        url: "/administration",
        Icon: Package,
        roles:[Role.Owner, Role.CoOwner, Role.RealOwner],
        submenu: [
            {
                id: 41,
                label: "Users",
                url: "/users",
                Icon: Users,
                roles:[Role.Owner, Role.CoOwner, Role.RealOwner],
                description:"View and manage all your agents & real owners"
            },
            // {
            //     id: 41,
            //     label: "Subscription & Payment",
            //     url: "/subscription",
            //     Icon: Users,
            //     roles:[Role.Owner, Role.CoOwner, Role.RealOwner],
            //     description:"View and manage all your agents & real owners"
            // },
        ],
    }
];

export default asideMenus;
