import {Button, Card, CardFooter, Col, Row, Text} from "@doar/components";
import {ArrowLeft, ArrowRight, X} from "react-feather";
import Joyride, { CallBackProps } from "react-joyride";
import {StyledButton} from "src/components/style";


export type TutorialStep = {
    target: string;
    title: string;
    description: string;
    note?: string;
}


const Tutorial = ({steps, run, onStateChanged}: { steps: TutorialStep[], run:boolean, onStateChanged?:(state:CallBackProps)=>void }) => {


    return (
        <Joyride
            steps={steps.map(step => {
                return {
                    target: step.target,
                    content: <Col textAlign={'start'} pl={0} pr={0} mt={10}>
                        <Text fontWeight={600} fontSize={14}>{step.title}</Text>
                        <Text fontSize={14}>{step.description}</Text>
                        {step?.note && <Text fontSize={12}>{step.note}</Text>}
                    </Col>
                }
            })}
            styles={{
                options: {
                    arrowColor: '#e3ffeb',
                    primaryColor: '#0168fa',
                    overlayColor:"#000000b5",
                    textColor: '#004a14',
                    width: undefined,
                    zIndex: 1100,
                }
            }}
            tooltipComponent={({
                                   continuous,
                                   index,
                                   step,
                                   backProps,
                                   closeProps,
                                   primaryProps,
                                   tooltipProps,
                                   size,
                isLastStep
                               }) => (
                <Card {...tooltipProps} p={10} pl={15} pr={15} style={{
                    maxWidth:500
                }}>
                    <>{step.content}</>
                    <Row justifyContent={'between'} m={0} mt={20} alignItems={'end'}>
                        {index > 0 && (
                            <StyledButton variant={'texted'} hasIcon={true} {...backProps}>
                                <ArrowLeft/> {"back"}
                            </StyledButton>
                        )}
                        {continuous && (
                            <Text mb={0} ml={2} mr={2}>{`${index+1} of ${size}`}</Text>
                        )}
                        {continuous && (
                            <StyledButton variant={'texted'} hasIcon={true} {...primaryProps}>
                                {`${isLastStep?"Finish":"Next"}`}
                                <ArrowRight/>
                            </StyledButton>
                        )}
                        {(
                            <StyledButton style={{
                                position: "absolute",
                                top: 5,
                                right: 5
                            }} variant={'texted'} {...closeProps}>
                                <X size={20}/>
                            </StyledButton>
                        )}
                    </Row>
                </Card>
            )}
            run={run}
            continuous={true}
            showProgress={true}
            scrollToFirstStep={true}
            disableOverlayClose={true}
            callback={onStateChanged}
            spotlightClicks={true}
        />
    );
};

export default Tutorial;
