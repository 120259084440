import classnames from "clsx";
import { StyledCard } from "./style";
import { ICardProps } from "./types";

const Card = ({ children, className, color, cursor, style,  onClick, colRef, ...restProps }: ICardProps) => {
    return (
        <StyledCard
            className={classnames(className, "card")}
            {...restProps}
            $color={color}
            $cursor={cursor}
            style={style}
            onClick={onClick}
            colRef={colRef}
        >
            {children}
        </StyledCard>
    );
};

export default Card;
