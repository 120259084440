import styled, {device, themeGet, css} from "@doar/shared/styled";
import {Anchor} from "@doar/components";

export const StyledLogo = styled(({...rest}) => <Anchor {...rest} />)`
  font-size: 22px;
  font-weight: 700;
  letter-spacing: -1px;
  margin-top: -3px;
  color: ${themeGet("colors.cod")};
  display: flex;
  justify-content: start;
  align-items: center;

  span {
    ${device.small} {
      display: inline-block;
    }
    &.right {
      font-weight: 700;
      color: ${themeGet("colors.primary")};
    }
  }

  img {
    height: 30px;
    min-width: 30px;
    padding-right: 5px;
    filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(196deg) brightness(95%) contrast(100%);
  }

  ${device.large} {
    font-size: 30px;
  }

  ${({theme}) =>
          theme.name === "dark" &&
          css`
            color: ${themeGet("colors.white")};
          `}
`;
