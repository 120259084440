import LandingHeader from "../landing-header";
import SEO from "../../../components/seo";
import Content from "../content";
import ContentBody from "../content-body";
import LandingMenu from "../landing-menu";
import {useRef} from "react";


interface IProps {
    aside?: "minimize";
    sidebarLayout?: 1 | 2;
    children: React.ReactNode;
}

const LandingLayout = ({children, aside, sidebarLayout}: IProps) => {
    const menuRef = useRef<any>();


    return (
        <>
            <SEO/>
            <LandingMenu ref={menuRef}/>
            <Content fullHeight={true} aside={"hidden"} noMenu={true} style={{}}>
                <LandingHeader onOpenMenu={() => {
                    if (menuRef != null)
                        menuRef.current.showMenu()
                }}/>
                <ContentBody noPadding container={false} style={{display: "block"}}>
                    {children}
                </ContentBody>
            </Content>
        </>
    );
};

LandingLayout.defaultProps = {
    sidebarLayout: 1,
};

export default LandingLayout;
