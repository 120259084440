import { useEffect, useState } from "react";
import {
    ChevronDown,
    Edit,
    User,
    Settings,
    HelpCircle,
    LogOut,
} from "react-feather";
import { Heading, Text, Nav, NavLink } from "@doar/components";
import {
    StyledLoggedInUser,
    StyledUserBtn,
    StyledUserCollapse,
    StyledUserData,
} from "./style";
import { authenticationService } from "../../settings/data";
import { Login } from "src/graphql/generated/owner-schema";

const LoggedinUser = ( {currentUser}:{currentUser: Login | undefined }) => {
    const [show, setShow] = useState(false);

    return (
        <StyledLoggedInUser className="aside-loggedin-user">
            <StyledUserData className="aside-loggedin-user-data">
                <StyledUserBtn
                    type="button"
                    onClick={() => setShow((prev) => !prev)}
                >
                    <Heading fontWeight={600} mb="0px">
                        {`${currentUser?.firstName ?? "Anonymous"} ${currentUser?.lastName ?? ''} `}
                    </Heading>
                    <ChevronDown size={16} strokeWidth="2.3px" />
                </StyledUserBtn>
                <Text color="text3" fontSize="12px">
                    {`${currentUser?.roles.reduce((x, y) =>
                        // @ts-ignore
                        `${x.replaceAll("_", " ").toCamelCase()} ${y.replaceAll("_", " ").toCamelCase()},`, '')}`}
                </Text>
            </StyledUserData>

            <StyledUserCollapse
                className="aside-loggedin-user-nav"
                $show={show}
            >
                <Nav vertical customStyle="aside">
                    {/*<NavLink path="#!">*/}
                    {/*    <Edit /> <span>Edit Profile</span>*/}
                    {/*</NavLink>*/}
                    {/*<NavLink path="#!">*/}
                    {/*    <User /> <span>View Profile</span>*/}
                    {/*</NavLink>*/}
                    {/*<NavLink path="#!">*/}
                    {/*    <Settings /> <span>Account Settings</span>*/}
                    {/*</NavLink>*/}
                    {/*<NavLink path="#!">*/}
                    {/*    <HelpCircle /> <span>Help Center</span>*/}
                    {/*</NavLink>*/}
                    <NavLink path="#!" onClick={() => authenticationService.logout()}>
                        <LogOut /> <span>Sign Out</span>
                    </NavLink>
                </Nav>
            </StyledUserCollapse>
        </StyledLoggedInUser>
    );
};

export default LoggedinUser;
