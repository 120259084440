import {useState, useEffect, useRef, useImperativeHandle, forwardRef} from "react";
import cn from "clsx";
import {Anchor, Nav, NavbarAside, Row} from "@doar/components";
import {asideMenuData} from "@doar/shared/data";
import {ISize} from "@doar/shared/types";
import Header from "../header";
import Scrollbar from "../../../components/scrollbar";
import AsideUser from "../../../components/aside-layout/user";
import {useWindowSize} from "../../../hooks";
import {
    StyledBody,
    StyledBodyInner,
    StyledBackdrop, StyledAside,
} from "./style";
import {House, Role} from "../../../graphql/generated/owner-schema";
import {DollarSign, Layers, PieChart} from "react-feather";
import {StyledMenuBtn, StyledNavLink} from "../landing-header/style";
import {StyledButton} from "../../../components/style";
import {dashboardUserService} from "../../../components/settings/data";

type TMaxText = "enter" | "leave";

interface IProps {
    layout?: "minimize";
    sidebarLayout?: 1 | 2;
}

const LandingMenu = forwardRef(({layout, sidebarLayout}: IProps, ref) => {
    const [show, setShow] = useState(false);
    const size: ISize = useWindowSize();
    const [currentRole, setCurrentRole] = useState<Role>();

    const landingMenu = [
        {
            id: 1,
            label: "Menu",
            url: "#",
            roles: [Role.Owner, Role.CoOwner, Role.Agent, Role.RealOwner],
            submenu: [
                {
                    id: 11,
                    label: "Features",
                    url: "/pricing#features",
                    Icon: Layers,
                    roles: [Role.Owner, Role.CoOwner, Role.Agent, Role.RealOwner],
                },
                {
                    id: 11,
                    label: "Pricing",
                    url: "/pricing",
                    Icon: DollarSign,
                    roles: [Role.Owner, Role.CoOwner, Role.Agent, Role.RealOwner],
                },
                {
                    id: 11,
                    label: "How To",
                    url: "/how-to",
                    Icon: PieChart,
                    roles: [Role.Owner, Role.CoOwner, Role.Agent, Role.RealOwner],
                },
            ],
        },
    ]


    const displayHandler = () => {
        setShow((prev) => !prev);
    };


    useImperativeHandle(ref, () => ({
        showMenu: () => {
            setShow(true);
        },
        hideMenu: () => {
            setShow(false);
        }
    }))


    useEffect(() => {
        console.log()
    }, [show, layout]);


    useEffect(() => {
        dashboardUserService.currentRoleSubject.asObservable().subscribe(currentRole => {
            setCurrentRole(currentRole)
        });
    }, [])


    return (
        <>
            <StyledBackdrop $show={show} onClick={displayHandler}/>
            <StyledAside
                $minimize={false}
                $mdMinimize={false}
                $maximize={true}
                $show={show}
            >
                <Header
                    minimizeHandler={() => {
                        setShow(false);
                    }}
                    displayHandler={displayHandler}
                    minimize={false}
                    mdMinimize={false}
                    show={true}
                    sidebarLayout={sidebarLayout}
                />
                <StyledBody
                    $minimize={false}
                    $mdMinimize={false}
                    $maximize={true}
                    $show={show}
                    className="aside-body"
                    // onMouseEnter={(e) => maximizeHandler(e, "enter")}
                    // onMouseLeave={(e) => maximizeHandler(e, "leave")}
                >
                    <Scrollbar>
                        <StyledBodyInner className="aside-body-inner">
                            <Row justifyContent={'center'} alignItems={'center'} mt={20} mb={20}
                                 pl={4} pr={4}><Anchor fontWeight={600} path={"/login"}>Login</Anchor></Row>
                            <NavbarAside menus={landingMenu} currentRole={currentRole}/>
                            <Row justifyContent={'center'} mt={20}>
                                <StyledButton>Get Started</StyledButton>
                            </Row>
                        </StyledBodyInner>
                    </Scrollbar>
                </StyledBody>
            </StyledAside>
        </>
    );
});

export default LandingMenu;
