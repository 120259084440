import {useState, useEffect, useRef} from "react";
import cn from "clsx";
import {NavbarAside} from "@doar/components";
import {asideMenuData} from "@doar/shared/data";
import {ISize} from "@doar/shared/types";
import Header from "../header";
import Scrollbar from "../../../components/scrollbar";
import AsideUser from "../../../components/aside-layout/user";
import {useWindowSize} from "../../../hooks";
import {
    StyledAside,
    StyledBody,
    StyledBodyInner,
    StyledBackdrop,
} from "./style";
import {Role} from "../../../graphql/generated/owner-schema";
import {dashboardUserService, getCurrentTutorialStatus, tutorialService} from "../../../components/settings/data";
import Tutorial, {TutorialStep} from "../../../components/widgets/tutorials";

type TMaxText = "enter" | "leave";

interface IProps {
    layout?: "minimize";
    sidebarLayout?: 1 | 2;
}

const Aisde = ({layout, sidebarLayout}: IProps) => {
    const [minimize, setMinimize] = useState(layout === "minimize");
    const [maximize, setMaximize] = useState(false);
    const [show, setShow] = useState(false);
    const size: ISize = useWindowSize();
    const maximized = useRef(false);
    const mdMinimize = useRef(true);
    const [currentRole, setCurrentRole] = useState<Role>();
    const [showTutorial, setShowTutorial] = useState(false);
    const menuSteps: TutorialStep[] = asideMenuData.flatMap(menuData => menuData.submenu).map((data => {

        return {
            target: `.${data.label.toLowerCase().replaceAll(" ", "-")}`,
            title: data.label,
            description: data.description,
        }
    }));

    const minimizeHandler = () => {
        setMinimize((prev) => !prev);
    };

    const displayHandler = () => {
        setMinimize(false);
        setShow((prev) => !prev);
    };

    useEffect(() => {
        if (!size.width) return;
        if (
            size.width > 991 &&
            size.width < 1200 &&
            !show &&
            layout !== "minimize"
        ) {
            setMinimize(true);
        }
        if (
            size.width >= 1200 &&
            maximized.current === false &&
            layout !== "minimize"
        ) {
            setMinimize(false);
            maximized.current = true;
        }
        if (size.width <= 1199) {
            maximized.current = false;
        }
        if (size.width <= 991) {
            setMinimize(false);
        }
    }, [size.width, show, layout]);

    const maximizeHandler = (e: React.MouseEvent, text: TMaxText) => {
        e.preventDefault();
        if (!minimize) return;
        if (text === "enter") {
            setMaximize(true);
        }
        if (text === "leave") {
            setMaximize(false);
        }
    };

    const minClass = minimize ? "minimize" : "";
    const maxClass = maximize ? "maximize" : "";


    useEffect(() => {
        dashboardUserService.currentRoleSubject.asObservable().subscribe(currentRole => {
            setCurrentRole(currentRole)
        });
        tutorialService.currentTutorialStatus.asObservable().subscribe(tutorialStatus => {
            setShowTutorial(tutorialStatus().menu ?? false)
        });
    }, [])


    return (
        <>
            <StyledBackdrop $show={show} onClick={displayHandler}/>
            <StyledAside
                className={cn(minClass, maxClass)}
                $minimize={minimize}
                $mdMinimize={mdMinimize.current}
                $maximize={maximize}
                $show={show}
            >
                <Header
                    minimizeHandler={minimizeHandler}
                    displayHandler={displayHandler}
                    minimize={minimize}
                    mdMinimize={mdMinimize.current}
                    show={show}
                    sidebarLayout={sidebarLayout}
                />
                <StyledBody
                    $minimize={minimize}
                    $mdMinimize={mdMinimize.current}
                    $maximize={maximize}
                    $show={show}
                    className="aside-body"
                    onMouseEnter={(e) => maximizeHandler(e, "enter")}
                    onMouseLeave={(e) => maximizeHandler(e, "leave")}
                >
                    <Scrollbar>
                        <StyledBodyInner className="aside-body-inner">
                            <AsideUser/>
                            <NavbarAside menus={asideMenuData} currentRole={currentRole}/>
                        </StyledBodyInner>
                    </Scrollbar>
                </StyledBody>
            </StyledAside>

            <Tutorial steps={menuSteps} run={showTutorial} onStateChanged={
                ({action, index, status, type}) => {
                    if (status == "finished") {
                        localStorage.setItem("currentTutorialStatus", JSON.stringify({
                            ...getCurrentTutorialStatus(),
                            menu: false
                        }))
                        tutorialService.currentTutorialStatus.next(() => getCurrentTutorialStatus())
                    }
                }
            }/>
        </>
    );
};

export default Aisde;
