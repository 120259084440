import {Suspense, lazy, useState, useEffect} from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet,
} from "react-router-dom";
import Preloader from "./components/preloader";
import Layout02 from "./layouts/layout-02";
import {PrivateRoute} from "./routes/PrivateRoute";
import {routes} from "./routes/routes";
import {NotificationType} from "./index";
import {getCookie, setCookie} from "react-use-cookie";
import {useAppSelector} from "./redux/hooks";
import {Role, useNewRentalMessageSubscription} from "./graphql/generated/owner-schema";
import moment from "moment";
import {toast} from "react-toastify";
import {ToastWithTitle} from "./components/settings";
import { handleErrorResponse, subscriptionClient} from "./components/settings/data";
import LandingLayout from "./layouts/layout-02/landing-layout";

const LandingPage = lazy(() => import("./pages/landing"));
const PricingPage = lazy(() => import("./pages/pricing"));
const ClassicPlusSignIn = lazy(() => import("./pages/classic-plus/signin"));
const ClassicPlusErrorNotFound = lazy(() => import("./pages/classic-plus/error-404"));

const App = () => {


    const [notifications, setNotifications] = useState<NotificationType[]>(JSON.parse(getCookie('notifications', '[]')));
    const { theme } = useAppSelector((state) => state.theme);

    useNewRentalMessageSubscription({
        onData: (dataOptions) => {
            setNotifications((current) => {
                const notis = [{
                    ...dataOptions?.data?.data?.message,
                    timestamp: new Date()
                }, ...current].filter(notification => moment().diff(moment(notification.timestamp), "day") < 1);
                setCookie('notifications', JSON.stringify(notis))
                return notis
            })
            toast.info(<ToastWithTitle title={dataOptions.data.data?.message?.title??"New Rental Message"}
                                       message={dataOptions.data.data?.message?.message??"Please check chats for messages from potential tenants"}/>,{
                theme:theme=="dark"?"dark":"light"
            });
        },
        client: subscriptionClient,
        shouldResubscribe: true,
        fetchPolicy: 'network-only',
        onError: (error) => {
            handleErrorResponse(error)
        }
    })


    return (
        <>
            <Router>
                <Suspense fallback={<Preloader/>}>
                    <Routes>
                        <Route
                            element={
                                <LandingLayout>
                                    <Outlet/>
                                </LandingLayout>
                            }
                        >
                        <Route path="/" element={<LandingPage/>}/>
                        <Route path="/pricing" element={<PricingPage/>}/>
                        </Route>

                        <Route
                            element={
                                <Layout02>
                                    <Outlet/>
                                </Layout02>
                            }
                        >

                            {
                                routes.map((route, index) =>
                                    <Route
                                        key={`route_${index}`}
                                        path={route.path}
                                        element={
                                            <PrivateRoute element={route.element}
                                                          allowedRoles={route.allowedRoles}/>
                                        }
                                    />)
                            }
                        </Route>
                        <Route path="/login" element={<ClassicPlusSignIn/>}/>
                        <Route path="*" element={<ClassicPlusErrorNotFound/>}/>
                    </Routes>
                </Suspense>
            </Router>
        </>
    );
};

export default App;
