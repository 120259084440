import styled, {
    device,
    SpaceProps,
    space,
    css,
    themeGet,
} from "@doar/shared/styled";

interface IProps extends SpaceProps {
    $fullHeight?: boolean;
    $align?: "top" | "center" | "bottom";
    $aside?: "minimize" | "hidden";
    $noMenu?: boolean;
}

const conentCSS = css<IProps>`
  height: 100vh;


  ${({$aside, $noMenu}) =>
          $aside !== "minimize" &&
          css`
            margin-left: 0px;
            ${device.large} {
              margin-left: 60px;
            }
            ${device.xlarge} {
              margin-left: 240px;
            }
          `}

  ${({$aside}) =>
          $aside == "hidden" &&
          css`
            margin-left: 0px;
            ${device.large} {
              margin-left: 0px;
            }
            ${device.xlarge} {
              margin-left: 0px;
            }
          `}

  ${({$fullHeight}) =>
          $fullHeight &&
          css`
            .content-body {
              min-height: calc(100vh - 60px);
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
          `}

  ${({$align}) =>
          $align &&
          $align !== "center" &&
          css`
            .content-body {
              & > .container {
                flex: 1;
              }
            }
          `}

  ${(props) =>
          props.theme.name === "dark" &&
          css`
            color: ${themeGet("colors.gray500")};
          `}
  ${space};

`;

export const StyledContent = styled.main`
  ${conentCSS}
`;
