import styled, {css, device, space, SpaceProps} from "@doar/shared/styled";

interface IProps extends SpaceProps {
    $noPadding?: boolean;
}

export const StyledBody = styled.div<IProps>`
  position: relative;


  padding: 15px 10px 15px 10px;
  /* height: calc(100% - 54px); */
  height: 100%;
  overflow-y: auto !important;

  ${device.small} {
    padding: 20px 10px 20px 10px;
  }

  ${device.large} {
    padding: 25px 10px 25px 10px;
    /* height: calc(100% - 60px); */
  }

  ${({$noPadding}) =>
          $noPadding &&
          css`
            padding: 0px;

            ${device.small} {
              padding: 0px;
            }

            ${device.large} {
              padding: 0px;
              /* height: calc(100% - 60px); */
            }
          `}

  ${space}
`;
