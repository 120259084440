import {IconProps, TTheme} from "@doar/shared/types";
import {ApolloClient, ApolloError, ApolloLink, InMemoryCache} from "@apollo/client";
// @ts-ignore
import {createUploadLink} from "apollo-upload-client";
import {useMemo} from "react";
import {BehaviorSubject} from "rxjs";
import firebase from "firebase";
import {getCookie} from "react-use-cookie";
import {toast} from "react-toastify";
import {
    ChatMessage,
    ChatMessagesConnection,
    House,
    HouseStatus,
    Login,
    LogoutDocument,
    Maybe,
    OffsetPageInfo, OwnerPermission,
    PaymentStatisticsQuery,
    Rental,
    RentalStatus,
    Role,
    Scalars,
    Tenant
} from "../../graphql/generated/owner-schema";
import {WebSocketLink} from "@apollo/client/link/ws";
import {ToastWithTitle} from "./index";
import {Droplet, Grid, Trash2, Truck, Tv, Wifi, Zap} from "react-feather";
import {Icon} from "leaflet";

const config = require("./config/config.json")


export const phoneNumberPrefix = '+254';
export const phoneRegex = /^([+])?(254)?(0)?([17])\d{8}\b/gm
export const passwordRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
export const passwordCountRegex = /(?=.{8,})/
export const passwordSpecialRegex = /(?=.*[!@#$%^&*])/
export const passwordNumericRegex = /(?=.*[0-9])/
export const passwordUppercaseCaseRegex = /(?=.*[A-Z])/
export const passwordLowercaseCaseRegex = /(?=.*[a-z])/


// Firebase
export const firebaseApp = firebase.initializeApp({
    apiKey: config.firebase.apiKey,
    authDomain: config.firebase.authDomain,
    databaseURL: config.firebase.databaseURL,
    projectId: config.firebase.projectId,
    storageBucket: config.firebase.storageBucket,
    messagingSenderId: config.firebase.messagingSenderId,
    appId: config.firebase.appId
});

export const SkinModes: TTheme[] = ["cool", "dark", "classic", "light"];

const ownerLink: ApolloLink = createUploadLink({
    uri: `${process.env.REACT_APP_OWNER_BACKEND_ENDPOINT || "http://localhost:4501"}/graphql`,
    credentials: "include",
});
const tenantLink: ApolloLink = createUploadLink({
    uri: `${process.env.REACT_APP_BACKEND_ENDPOINT || ""}/graphql`,
    credentials: "include",
});
export const apolloClient = new ApolloClient({
    link: ApolloLink.split(
        (operation) => operation.getContext().clientName === "owner",
        ownerLink,
        tenantLink
    ),
    cache: new InMemoryCache({
        resultCaching: false,
        addTypename: false,
    }),
});
export const useApolloClient = () => {
    const ownerClient = useMemo(
        () =>
            apolloClient,
        []
    );
    return ownerClient;
};

export const subscriptionClient = (new ApolloClient(
    {
        link: ApolloLink.from(
            [
                new WebSocketLink({
                    uri: `${process.env.REACT_APP_OWNER_BACKEND_WS_ENDPOINT}`,
                    options: {
                        reconnect: true,
                        connectionParams: {
                            authToken: JSON.parse(getCookie("owner", "{}")).jwtToken
                        }
                    }
                })
            ]
        ),
        cache: new InMemoryCache({
            resultCaching: false,
            addTypename: false,
        }),
    }));

declare global {
    export interface Array<T> {
        count(filterMethod: (value: T) => boolean): number
    }

    export interface Number {
        formatCurrency(n?: number, x?: number, s?: string, c?: string): string
    }

    export interface String {
        toCamelCase(removeSpaces?: boolean): string,

        ellipsizeMiddle(length?: number): string,

        splitByCaps(): string,
    }
}

Number.prototype.formatCurrency = function (n = 0, x = 3, s = ',', c = '.') {
    const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};
String.prototype.toCamelCase = function (removeSpaces = false) {
    return this.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
String.prototype.ellipsizeMiddle = function (n = 3) {
    return this.substr(0, n) + (this.length > n ? '...' : '') + (this.length > n * 2 ? this.substr(this.length - n, n) : '');
};
String.prototype.splitByCaps = function () {
    return this.split(/(?=[A-Z])/).reduce((a, b) => `${a} ${b}`, "");
};
Array.prototype.count = function (filterMethod) {
    return this.reduce((count, item) => filterMethod(item) ? count + 1 : count, 0);
}

export const dateTimeFormat = (format?: {
    localeMatcher?: "best fit" | "lookup";
    weekday?: "long" | "short" | "narrow";
    era?: "long" | "short" | "narrow";
    year?: "numeric" | "2-digit";
    month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
    day?: "numeric" | "2-digit";
    hour?: "numeric" | "2-digit";
    minute?: "numeric" | "2-digit";
    second?: "numeric" | "2-digit";
    timeZoneName?: "long" | "short";
    formatMatcher?: "best fit" | "basic";
    hour12?: boolean;
    timeZone?: string;
} | undefined) => new Intl.DateTimeFormat('en-US', format ?? {
    hour: '2-digit',
    minute: '2-digit',
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
})

const loginWithFirebasePhoneAuth = async (phone: string, theme?: TTheme) => {

    return await firebaseApp.auth().signInWithPhoneNumber(phone, new firebase.auth.RecaptchaVerifier('sign-in-button', {
        'size': 'invisible',
        'callback': () => {
            return;
        }
    })).then(function (confirmationResult) {
        toast.success(<ToastWithTitle title={"Verification code sent."}
                                      message={"Kindly check your phone for the code sent and enter it to proceed. Do not share the code with anyone!"}/>,
            {
                theme: theme == "dark" ? "dark" : "light"
            });
        return confirmationResult;
    }).catch((error) => {
        return null;
    });
}

const financialStatisticsSubject = new BehaviorSubject<PaymentStatisticsQuery | undefined>(undefined);

const currentUserSubject = new BehaviorSubject(() => {
        try {
            return JSON.parse(getCookie(process.env?.OWNER_COOKIE ?? 'owner'))
        } catch (e) {
            console.log(e)
            return null;
        }
    }
);

export type TutorialStatus = {
    welcome?: boolean;
    menu?: boolean;
    dashboard?: boolean;
    createHouse?: {
        locationType: boolean,
        apartmentAmmenities: boolean,
        houseDetails: boolean,
        houseAmmenities: boolean,
        location: boolean,
        createHouses: boolean,
    };
    AddTenant?: boolean;
    receiveRent?: boolean;
}
const defaultTutorialsState: TutorialStatus = {
    welcome: true,
    menu: true,
    dashboard: true,
    createHouse: {
        locationType: true,
        apartmentAmmenities: true,
        houseDetails: true,
        houseAmmenities: true,
        location: true,
        createHouses: true,
    },
    AddTenant: true,
    receiveRent: true,
};
export const getCurrentTutorialStatus = () => (JSON.parse(localStorage.getItem("currentTutorialStatus") ?? JSON.stringify(
    defaultTutorialsState
)) as TutorialStatus)
const currentTutorialStatus = new BehaviorSubject<() => TutorialStatus>(getCurrentTutorialStatus);

const currentRoleSubject = new BehaviorSubject<Role>((localStorage.getItem("currentRoleSubject") as Role )??(JSON.parse(getCookie(process.env?.OWNER_COOKIE ?? 'owner', "{}")) as Login).roles?.shift() ?? Role.Owner);
const createHouseSubject = new BehaviorSubject<() => boolean>(() => {
        return false;
    }
);

const addTenantSubject = new BehaviorSubject(() => {
        return false;
    }
);
const receiveRentSubject = new BehaviorSubject(() => {
        return false;
    }
);

const chatMessageSubject = new BehaviorSubject<ChatUser | undefined>(undefined);
const newMessageSubject = new BehaviorSubject<NewMessage | undefined>(undefined);

export type PartialRentalRequest = { __typename?: 'Rental', id: string, createdAt?: any | null, tenant: { __typename?: 'Tenant', id: string, firstName?: string | null, lastName?: string | null, mobileNumber: string, email?: string | null, media?: { __typename?: 'Media', thumbnails: Array<string>, addresses: Array<string> } | null }, house: { __typename?: 'House', id: string, houseNumber?: string | null, district?: string | null, constituency?: string | null, county?: string | null, apartment?: { __typename?: 'Apartment', name: string, district: string, constituency: string, county: string } | null, media?: { __typename?: 'Media', thumbnails: Array<string>, addresses: Array<string> } | null } }
export type PartialAgent = { __typename?: 'Agent', id: string, firstName?: string | null, lastName?: string | null, mobileNumber: string, media?: { __typename?: 'Media', addresses: Array<string>, thumbnails: Array<string> } | null, agentDetails?: Array<{ __typename?: 'AgentDetails', housesAggregate: Array<{ __typename?: 'AgentDetailsHousesAggregateResponse', count?: { __typename?: 'AgentDetailsHousesCountAggregate', id?: number | null } | null }> }> | null }
export type PartialTenant = { __typename?: 'Tenant', id: string, firstName?: string | null, lastName?: string | null, mobileNumber: string, email?: string | null, media?: { __typename?: 'Media', thumbnails: Array<string>, addresses: Array<string> } | null }
export type PartialMedia = { __typename?: 'Media', addresses: Array<string>, thumbnails: Array<string> }
const selectedRequestSubject = new BehaviorSubject<PartialRentalRequest | undefined>(undefined);
const selectedAgentSubject = new BehaviorSubject<PartialAgent | undefined>(undefined);
const newRequestSubject = new BehaviorSubject<PartialRentalRequest | undefined>(undefined);

const confirmFirebaseVerificationCode = async (code: string, confirmationResult: any, theme?: TTheme) => {

    return confirmationResult.confirm(code).then(async (verificationResult: any) => {
        return verificationResult;
    }).catch((error: any) => {
        toast.error(<ToastWithTitle title={"Code Verification Failed."}
                                    message={error?.message ?? "OTP Code verification failed. Kindly confirm that you've entered the correct code"}/>, {
            theme: theme == "dark" ? "dark" : "light"
        });
        return error;
    })
}

function Logout() {
    apolloClient.mutate({
        mutation: LogoutDocument,
        context: {clientName: "owner"}
    }).then((response) => {
        firebaseApp.auth().signOut()
        window.location.replace("/login")
    }).catch((error) => {
        firebaseApp.auth().signOut()
    })
}


export function handleErrorResponse(error: ApolloError) {
    console.log(error)
    if (error.message == "GqlAuthGuard") {
        authenticationService.logout()
        window.location.replace("/login");
    }
}

export const dashboardUserService = {
    currentRoleSubject: currentRoleSubject,
};

export const tutorialService = {
    currentTutorialStatus: currentTutorialStatus,
};

export const authenticationService = {
    loginWithPhone: loginWithFirebasePhoneAuth,
    confirmVerificationCode: confirmFirebaseVerificationCode,
    currentUser: currentUserSubject.asObservable(),
    logout: Logout
};

export const ownerActionsService = {
    createHouseSubject: createHouseSubject,
    receiveRentSubject: receiveRentSubject,
    addTenantSubject: addTenantSubject
};

export const chatService = {
    selectedChatSubject: chatMessageSubject,
    newMessageSubject: newMessageSubject,
};

export const rentingRequestService = {
    selectedRequestSubject: selectedRequestSubject,
    newRequestSubject: newRequestSubject,
};
export const agentService = {
    selectedAgentSubject: selectedAgentSubject,
};

export const statsService = {
    financialStatisticsSubject: financialStatisticsSubject,
};

export type NewMessage = {
    houseId: Maybe<string> | undefined,
    message: ChatMessage | { __typename?: 'ChatMessage', sentAt: string, tenantId?: string | null, ownerId?: string | null, content: string }
}

export type ChatUser = {
    __typename?: 'Chat';
    createdAt: Scalars['Timestamp'];
    house?: Maybe<House | {
        __typename?: 'House',
        id: string, status: HouseStatus, country?: string | null, county?: string | null, constituency?: string | null, district?: string | null, apartment?: { __typename?: 'Apartment', id: string, name: string, country?: string | null, county: string, constituency: string, district: string } | null, rentals?: { __typename?: 'Rental', id: string, status: RentalStatus, rentEntryTimestamp?: any | null, tenant: { __typename?: 'Tenant', firstName?: string | null, lastName?: string | null, media?: { __typename?: 'Media', addresses: Array<string>, thumbnails: Array<string> } | null }, unpaidAmount: Array<{ __typename?: 'RentalPaymentPeriodsAggregateResponse', total?: { __typename?: 'RentalPaymentPeriodsSumAggregate', amount?: number | null } | null }> } | null
    } | {
        __typename?: 'House',
        id: string,
        houseNumber?: string | null,
        apartment?: { __typename?: 'Apartment', name: string } | null,
        media?: { __typename?: 'Media', addresses: Array<string>, thumbnails: Array<string> } | null
    }>;
    houseId?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
    messages?: Maybe<ChatMessagesConnection | {
        __typename?: 'ChatMessagesConnection';
        /** Array of nodes. */
        nodes: Array<ChatMessage | { __typename?: 'ChatMessage', sentAt: string, tenantId?: string | null, ownerId?: string | null, content: string }>;
        /** Paging information */
        pageInfo?: OffsetPageInfo;
    }>;
    ownerId?: Maybe<Scalars['String']>;
    tenant?: Maybe<Tenant | { __typename?: 'Tenant', firstName?: string | null, lastName?: string | null, media?: { __typename?: 'Media', addresses: Array<string>, thumbnails: Array<string> } | null } | {
        __typename?: 'Tenant',
        firstName?: string | null,
        lastName?: string | null,
        media?: { __typename?: 'Media', addresses: Array<string>, thumbnails: Array<string> } | null
    }>;
    tenantId?: Maybe<Scalars['String']>;
}

export const defaultSearchLocation = {
    lat: -1.23075,
    lng: 36.87586
}

export const houseFeatures = {
    "water": <Droplet/>,
    "wifi": <Wifi/>,
    "electricity": <Zap/>,
    "balcony": <Grid/>,
    "waste": <Trash2/>,
    "transportation": <Truck/>,
    "fireDetector": <Zap/>,
    "cableTV": <Tv/>
}

export const defaultsConfig = {
    maxBedrooms: 10,
    maxBathrooms: 10,
    maxImageSize: 5000000
}

export const fetchLocationAutocomplete = (query: string) => {
    return fetch("https://photon.komoot.io/api/?q=" + encodeURI(query) + "&bbox=33.913,-4.689,41.900,5.042", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
