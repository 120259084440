import {HelpCircle, Grid, AlignLeft, Menu} from "react-feather";
import {Anchor, Col, Input, Nav, NavLink, Row, Text} from "@doar/components";
import ContentSearch from "../../../components/aside-layout/content-search";
import {StyledHeader, StyledNavLink} from "./style";
import {StyledButton} from "../../../components/style";
import {StyledForm} from "../../../components/aside-layout/content-search/style";
import AsideLogo from "../../../components/aside-layout/logo";
import {StyledMenuBtn} from "./style";
import {useEffect, useState} from "react";
import {Login} from "../../../graphql/generated/owner-schema";
import {authenticationService} from "../../../components/settings/data";

const LandingHeader = ({onOpenMenu}: { onOpenMenu: () => void }) => {

    const [currentUser, setCurrentUser] = useState<Login>();


    useEffect(() => {
        authenticationService.currentUser.subscribe(currentUserCallback => {
            const user = currentUserCallback();
            setCurrentUser(user)
        });
    }, [])
    return (
        <StyledHeader>
            <StyledForm>
                <AsideLogo/>
                <StyledNavLink path="#!">
                    <Row justifyContent={'center'} alignItems={'center'} style={{display: 'flex', height: '100%'}}
                         pl={4} pr={4}><Text fontWeight={600} style={{textWrap: "nowrap"}}>Features</Text></Row>
                </StyledNavLink>
                <StyledNavLink path="#!">
                    <Row justifyContent={'center'} alignItems={'center'} style={{display: 'flex', height: '100%'}}
                         pl={4} pr={4}><Anchor fontWeight={600} path={"/pricing"}>Pricing</Anchor></Row>
                </StyledNavLink>
                <StyledNavLink path="#!">
                    <Row justifyContent={'center'} alignItems={'center'} style={{display: 'flex', height: '100%'}}
                         pl={4} pr={4}><Text fontWeight={600} style={{textWrap: "nowrap"}}>How to</Text></Row>
                </StyledNavLink>
            </StyledForm>
            <Nav>
                <StyledNavLink path="#!">
                    <Row justifyContent={'center'} alignItems={'center'} style={{display: 'flex', height: '100%'}}
                         pl={4} pr={4}>
                        {!currentUser && <Anchor fontWeight={600} path={"/login"}>Sign In</Anchor>}
                        {currentUser && <Anchor fontWeight={600} path={"/logout"}>Sign Out</Anchor>}
                    </Row>
                </StyledNavLink>

                {!currentUser &&
                <StyledNavLink path="#"><StyledButton>Get Started</StyledButton></StyledNavLink>}
                {currentUser &&
                <StyledNavLink path="/dashboard"><StyledButton>View Dashboard</StyledButton></StyledNavLink>}


                <StyledMenuBtn className="minimize-btn" onClick={() => onOpenMenu()}>
                    <Menu size={18} strokeWidth="2.5px"/>
                </StyledMenuBtn>
            </Nav>
        </StyledHeader>
    );
};

export default LandingHeader;
