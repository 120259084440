/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";
import ThemeProvider from "./redux/providers/theme-provider";
import PersistProvider from "./redux/providers/persist-provider";
import {store} from "./redux/store";
import App from "./App";
import {ApolloProvider} from "@apollo/client";
import {handleErrorResponse, subscriptionClient, useApolloClient} from "./components/settings/data";
import {getCookie, setCookie} from "react-use-cookie";
import moment from "moment";
import {useState} from "react";
import {useNewRentalMessageSubscription} from "./graphql/generated/owner-schema";
import {toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ToastWithTitle } from "./components/settings";
import {useAppSelector} from "./redux/hooks";

const container = document.getElementById("root")!;
const root = createRoot(container);


export type NotificationType = { title?: string | undefined, message?: string | undefined, timestamp: Date }
const Root = () => {
    const apolloClient = useApolloClient();

    return (<ApolloProvider client={apolloClient}>
        <Provider store={store}>
            <PersistProvider>
                <ThemeProvider>
                    <>
                        <App/>
                        <ToastContainer/>
                    </>
                </ThemeProvider>
            </PersistProvider>
        </Provider>
    </ApolloProvider>);
}

root.render(<Root/>);
