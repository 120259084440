import styled, {device, css, themeGet} from "@doar/shared/styled";

interface IProps {
    $minimize: boolean;
    $maximize: boolean;
    $mdMinimize: boolean;
}

interface IVisibleProps {
    $show: boolean;
}

interface IAsideProps extends IProps, IVisibleProps {
}

export const StyledAside = styled.aside<IAsideProps>`
  width: 240px;
  height: 100%;
  transform: translateX(100%);
  transition-duration: 500ms;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: block;

  ${device.small} {
    display: none;
  }

  .scrollbar {
    .ps__thumb-x {
      display: none;
    }
  }

  ${({$show}) =>
          $show &&
          css`
            transform: translateX(0);
          `}
  .scrollbar {
    width: 240px;
  }

  .aside {
    &-body {
      width: 100%;
      display: block;

      &-inner {
        //padding: 20px;
      }
    }

    &-user {
      .aside-alerts {
        opacity: 1;
        visibility: visible;
        position: relative;
      }
    }

    &-loggedin-user {
      &-nav {
        span {
          position: relative;
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &-navbar {
      &-link {
        span {
          position: relative;
          opacity: 1;
          visibility: visible;
        }

        &:after {
          display: block;
        }
      }
    }
  }

  ${device.large} {
    transform: translateX(0);
  }
`;

export const StyledBody = styled.div<IAsideProps>`
  height: 100%;
  padding-bottom: 70px;
  transition: all 0.3s;
  border-top: 1px solid ${themeGet("colors.border")};

  ${({$minimize}) =>
          $minimize &&
          css`
            width: 60px;
            transition: none;
          `}
  ${({$mdMinimize, $show}) =>
          $mdMinimize &&
          !$show &&
          css`
            ${device.lgToXl} {
              width: 60px;
              transition: none;
            }
          `}

  ${(props) =>
          props.theme.name !== "dark" &&
          css`
            background-color: #fff;
            border-right: 1px solid ${themeGet("colors.border")};
          `}
  ${(props) =>
          props.theme.name === "dark" &&
          css`
            background-color: ${themeGet("colors.gray900")};
          `}
`;

export const StyledBodyInner = styled.div`
  //padding: 25px;
  position: absolute;
  inset: 0;
`;

export const StyledBackdrop = styled.div<IVisibleProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(28, 39, 60, 0.7);
  opacity: 0;
  z-index: 900;
  visibility: hidden;

  ${({$show}) =>
          $show &&
          css`
            opacity: 1;
            visibility: visible;
          `}
  ${device.large} {
    display: none;
  }
`;
