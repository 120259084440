import React, {useEffect, useState} from 'react';
import {Route, Navigate, Outlet} from 'react-router-dom';
import {getCookie, deleteCookie} from "react-use-cookie";
import {authenticationService} from "../components/settings/data";


export const PrivateRoute = ({ element, allowedRoles}) => {


    const [currentUser, setCurrentUser] = useState(
        () => {
        try {
            return JSON.parse(getCookie(process.env?.OWNER_COOKIE ?? 'owner'))
        } catch (e) {
            return null;
        }
    }
    );


    if (!currentUser) {
        // not logged in so redirect to login page with the return url
        return <Navigate to={{pathname: '/login'}}/>
    }

    // check if route is restricted by role
    if (!allowedRoles || (allowedRoles?.filter(allowedRole => currentUser.roles.includes(allowedRole))?.length ?? 0) <= 0) {
        // role not authorised so redirect to home page
        return <Navigate to={{pathname: '/login'}}/>
    }

    return element;
}
