import { Container } from "@doar/components";
import { SpaceProps } from "@doar/shared/styled";
import { StyledBody } from "./style";

interface IProps extends SpaceProps {
    children: React.ReactNode;
    container?: boolean;
    noPadding?:boolean;
    style?:any;
}

const ContentBody = ({ children, noPadding,  container, style, ...rest }: IProps) => {
    return (
        <StyledBody className="content-body" $noPadding={noPadding} {...rest} style={style}>
            {container && (
                <Container className="container" px="0" mb="50px">
                    {children}
                </Container>
            )}
            {!container && <>{children}</>}
        </StyledBody>
    );
};

ContentBody.defaultProps = {
    container: true,
};

export default ContentBody;
