import {Role} from "../graphql/generated/owner-schema";
import {lazy} from "react";

const ClassicPlusSignUp = lazy(() => import("../pages/classic-plus/signup"));
const ClassicPlusDashboardFour = lazy(() => import("../pages/classic-plus/dashboard-four"));
const DashboardTraction = lazy(() => import("../pages/classic-plus/dashboard-traction"));
const ClassicPlusChat = lazy(() => import("../pages/classic-plus/apps/chat"));
const PropertyModules = lazy(() => import("../components/properties"));
const MaintenanceModules = lazy(() => import("../components/maintenance"));
const PaymentAccountsModules = lazy(() => import("../components/payment-accounts"));
const AccountingPage = lazy(() => import("../pages/classic-plus/accounting"));
const ReportingPage = lazy(() => import("../pages/classic-plus/reporting"));
const UserModules = lazy(() => import("../components/users"));
const TenantsModules = lazy(() => import("../components/tenants"));
const ClassicPlusContacts = lazy(
    () => import("../pages/classic-plus/apps/contacts")
);
export const routes = [
    {path: "/dashboard", element: <ClassicPlusDashboardFour/>, allowedRoles: [Role.Owner, Role.CoOwner, Role.Agent]},
    {path: "/insights", element: <DashboardTraction/>, allowedRoles: [Role.Owner, Role.CoOwner, Role.Agent]},
    {path: "/properties", element: <PropertyModules/>, allowedRoles: [Role.Owner, Role.CoOwner, Role.Agent]},
    {path: "/tenants", element: <TenantsModules/>, allowedRoles: [Role.Owner, Role.CoOwner, Role.Agent]},
    {path: "/users", element: <UserModules/>, allowedRoles: [Role.Owner, Role.CoOwner, Role.Agent]},
    {path: "/maintenance", element: <MaintenanceModules/>, allowedRoles: [Role.Owner, Role.CoOwner, Role.RealOwner]},
    {path: "/messages", element: <ClassicPlusChat/>, allowedRoles: [Role.Owner, Role.CoOwner, Role.Agent]},
    {path: "/contacts", element: <ClassicPlusContacts/>, allowedRoles: [Role.Owner, Role.CoOwner, Role.Agent]},
    {path: "/reports", element: <ReportingPage/>, allowedRoles: [Role.Owner, Role.CoOwner, Role.RealOwner]},
    {path: "/payments", element: <ClassicPlusDashboardFour/>, allowedRoles: [Role.Owner, Role.CoOwner, Role.RealOwner]},
    {path: "/accounting", element: <AccountingPage/>, allowedRoles: [Role.Owner, Role.CoOwner, Role.RealOwner]},
]
