import { StyledLogo } from "./style";
import Logo from "@doar/shared/images/logo/logo.svg";

const AsideLogo = () => {
    return (
        <StyledLogo className="aside-logo" path="/">
            <img alt={'logo'} src={Logo} /> <span>Keja</span><span className={'right'}>Rent</span>
        </StyledLogo>
    );
};

export default AsideLogo;
